import { memo } from "react";
import PropTypes from "prop-types";
import { RATING_TYPE } from "app/constants";
import TripAdvisor from "app/pages/FicheProduit/components/TripAdvisor/TripAdvisor";
import HolidayCheck from "app/pages/FicheProduit/components/HolidayCheck/HolidayCheck";
import { isEmpty } from "lodash";

const ProductRating = ({
	tripAdvisor = {},
	holidayCheck = {},
	ratingType,
	isCompact = false,
	locationName,
}) => {
	return (
		<div className="product-rating" data-testid="product-rating">
			{ratingType === RATING_TYPE.TRIP_ADVISOR &&
				!isEmpty(tripAdvisor) &&
				tripAdvisor.rating > 0 &&
				tripAdvisor.reviewsCount > 0 && (
					<TripAdvisor
						id={tripAdvisor.id}
						rating={tripAdvisor.rating}
						reviewsCount={tripAdvisor.reviewsCount}
						awardLabel={tripAdvisor.awardLabel}
						locationName={locationName}
					/>
				)}

			{ratingType === RATING_TYPE.HOLIDAY_CHECK &&
				!isEmpty(holidayCheck) &&
				holidayCheck.averageRating > 0 &&
				holidayCheck.countReviews > 0 && (
					<HolidayCheck
						averageRating={holidayCheck.averageRating}
						countReviews={holidayCheck.countReviews}
						recommendation={holidayCheck.recommendation}
						size={holidayCheck.size}
						isCompact={isCompact}
						{...holidayCheck.isCompact && { isCompact: holidayCheck.isCompact }}
					/>
				)}
		</div>
	);
};

ProductRating.propTypes = {
	tripAdvisor: PropTypes.object,
	holidayCheck: PropTypes.object,
	ratingType: PropTypes.string,
	isCompact: PropTypes.bool,
	locationName: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

export default memo(ProductRating);
